import { PureComponent, useEffect } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line no-restricted-imports
import isEqual from "lodash/isEqual";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { useParams, useNavigate, Navigate } from "react-router-dom";

import { captureException } from "util/exception";
import LoadingIndicator from "common/core/loading_indicator";
import { useFeatureFlag } from "common/feature_gating";
import { segmentTrack } from "util/segment";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import { useMutation } from "util/graphql";
import { QueryWithLoading, isGraphQLError } from "util/graphql/query";
import { useRawMutation } from "util/graphql/mutation";
import { getNoteFromInstructions } from "util/notary_instructions";
import { formattedPropertyAddress } from "util/mortgage/transaction";
import { encodeCustomerSigners } from "util/customer_signers";
import { transactionEditRouteV3, TRANSACTION_PATH, transactionDetailsRoute } from "util/routes";
import { addError } from "redux/actions/errors";
import store from "redux/store";
import UpsertDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/upsert_document_bundle_instruction_mutation.graphql";
import DeleteDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/delete_document_bundle_instruction_mutation.graphql";
import SendOrganizationTransactionMutation from "common/transactions/graphql/mutations/send_organization_transaction_mutation.graphql";
import DeleteOrganizationTranasctionCustomerMutation from "common/transactions/graphql/mutations/delete_organization_transaction_customer_mutation.graphql";
import { useActiveOrganization } from "common/account/active_organization";
import {
  AuthTypes,
  OrganizationTransactionLabels,
  OrganizationTransactionContactRoleType,
  OrgTransactionStates,
} from "graphql_globals";
import { ENABLE_ENOTES_IN_HYBRIDS } from "constants/feature_gates";
import {
  DEFAULT_TRANSACTION_NAME,
  SIGNING_SCHEDULE_TYPES,
  ALL_LENDER_TRANSACTION_TYPES,
  LENDER_TRANSACTION_TYPES,
  LENDER_HYBRID_TRANSACTION_TYPES,
  TRANSACTION_TYPE_OTHER,
  MORTGAGE_TRANSACTION_REQUIREMENTS,
} from "constants/transaction";
import { ERROR_TYPES } from "constants/errors";
import { EVENT } from "constants/analytics";
import { newPathWithPreservedSearchParams } from "util/location";
import { COLLABORATORS } from "constants/points_of_contact";
import { UNASSIGNED as UNASSIGNED_CLOSER } from "common/transactions/closer_assignment";
import {
  serializeSigningSchedule,
  deserializeSigningSchedule,
} from "common/details/meeting/notary_details/items/signing_schedule_util";
import { usePermissions } from "common/core/current_user_role";
import { getRequirements } from "common/mortgage/transactions/requirements_service/service";
import { isConditional } from "util/requirements";
import SaveEnoteSeedMutation from "common/mortgage/transactions/edit/sub_forms/enote_section/save_enote_seed_mutation.graphql";
import RemoveEnoteSeedMutation from "common/mortgage/transactions/edit/sub_forms/enote_section/remove_enote_seed_mutation.graphql";
import UpdateMortgageBorrowerMutation from "common/mortgage/transactions/edit/sub_forms/enote_section/update_mortgage_borrower_mutation.graphql";
import RemoveDocumentFromTransactionMutation from "common/mortgage/transactions/edit/sub_forms/enote_section/remove_document_from_transaction_mutation.graphql";
import AddEnoteToTransactionMutation from "common/mortgage/transactions/edit/sub_forms/enote_section/add_enote_to_transaction_mutation.graphql";
import UpdatePaperNoteConsentMutation from "common/mortgage/transactions/edit/sub_forms/enote_section/update_paper_note_consent_mutation.graphql";
import {
  useTransactionCreationV3,
  TRANSACTION_CREATION_V3_OPT_OUT_TAG,
} from "common/transaction_creation/v3/detection";
import { TransactionErrorRedirect } from "common/transaction_creation/v3/form";

import SendOrganizationTransactionToClosingOpsMutation from "./send_organization_transaction_to_closing_ops_mutation.graphql";
import SendOrganizationTransactionToTitleAgencyMutation from "./send_organization_transaction_to_title_agency_mutation.graphql";
import UpdateOrganizationTransactionMutation from "./update_organization_transaction_mutation.graphql";
import TransactionEditQuery from "./transaction_edit_query.graphql";
import TransactionEditForm from ".";

const messages = defineMessages({
  updateError: {
    id: "d942d776-daeb-4cbc-a550-2491905c8f2b",
    defaultMessage: "There was an issue updating the transaction.",
  },
  unexpectedEnoteSeedError: {
    id: "f4bb202b-81c5-4814-a7c6-f8eb58a19734",
    defaultMessage: "There was an issue creating/updating the enote seed.",
  },
  genericError: {
    id: "4d40e486-3e7f-4d15-9068-a98e8692023c",
    defaultMessage: "Something went wrong",
  },
  vaultAuthenticationFailed: {
    id: "2033afd7-37e8-4ac4-9c66-482efaf8f821",
    defaultMessage:
      "We were unable to connect to your vault provider. Please contact your account manager to verify your credentials.",
  },
  duplicateMinError: {
    id: "33fe1495-f6ff-4aab-8367-2f6fa064e70d",
    defaultMessage: "This MIN has already been used.",
  },
  noAvailableNotary: {
    id: "edf8a924-2ac5-4bbd-853a-4b8c14a3b7b2",
    defaultMessage:
      "There is no notary that can meet the transaction requirements set by the title agency and underwriter. Select a different title agency and/or underwriter",
  },
});

const CX_NAME = "LenderEditTransactionContainer";
const SUPPRESS_OPTIONS_ANALYTICS = { suppressAnalytics: true };
const LENDER_EDIT_QUERY_USER_TAG_LIST = [TRANSACTION_CREATION_V3_OPT_OUT_TAG];

const hasCollaboratorSection = (nonOnboardedTitleEnabled, transactionType) => {
  return (
    nonOnboardedTitleEnabled &&
    [
      LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_REFINANCE,
      LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_PURCHASE_BUYER_LOAN,
      LENDER_TRANSACTION_TYPES.REFINANCE,
      LENDER_TRANSACTION_TYPES.PURCHASE_BUYER_LOAN,
      TRANSACTION_TYPE_OTHER,
    ].includes(transactionType)
  );
};

const addCollabSupportToPointsOfContact = (pointsOfContact) => {
  const collabIndex = pointsOfContact.findIndex(({ role, email }) => {
    return COLLABORATORS.includes(role) && !!email;
  });
  if (collabIndex < 0) {
    return [
      {
        role: OrganizationTransactionContactRoleType.TITLE_AGENT,
        accessToTransaction: true,
        shownToSigner: true,
      },
      ...pointsOfContact,
    ];
  }

  return [
    pointsOfContact[collabIndex],
    ...pointsOfContact.slice(0, collabIndex),
    ...pointsOfContact.slice(collabIndex + 1),
  ];
};

const normalizePointsOfContact = (nonOnboardedTitleEnabled, transactionType, contacts) => {
  // if there is a title agency section, then the first point of contact
  // needs to be removed, potentially, if it has not been filled in. Whether it needs
  // to be filled in depends on various external factors, which this layer of code
  // is not concerned with (that code sits in the validation layer). All it knows is that it needs to remove the first contact, which
  // we've set to be the title collaborator, if it has no email and no phone number, because one
  // of the two are required to create a contact.
  // In addition, we want to remove the title collab contact if the input email is associated
  // with a TA org as a shared inbox email. We do this by passing in the lookup object, which exists in
  // redux form state for reasons explained in src/lender_portal/transactions/edit/index.js

  if (hasCollaboratorSection(nonOnboardedTitleEnabled, transactionType)) {
    if (
      (!contacts[0].email && !contacts[0].phoneNumber) ||
      (!contacts[0].firstName && !contacts[0].lastName)
    ) {
      return contacts.slice(1);
    }
  }
  return contacts;
};

const getEnoteSaveErrorString = (err, intl) => {
  const errorMessage = err.graphQLErrors[0].message;
  switch (errorMessage) {
    case "authentication_failed":
      return intl.formatMessage(messages.vaultAuthenticationFailed);
    case "duplicate_min":
      return intl.formatMessage(messages.duplicateMinError);
    default:
      return intl.formatMessage(messages.unexpectedEnoteSeedError);
  }
};

const getSendTransactionErrorString = (err, intl) => {
  const errorMessage = err?.graphQLErrors?.[0]?.message;
  if (errorMessage === "transaction_not_serviceable_by_any_notary") {
    return intl.formatMessage(messages.noAvailableNotary);
  }

  return errorMessage;
};

class EditTransactionContainer extends PureComponent {
  constructor(props) {
    super(props);
    const {
      viewer,
      transaction,
      placeOrderLenderEnabledFlag,
      nonOnboardedTitleEnabled,
      hasPermissionFor,
    } = props;

    const {
      document_bundle: { instructions },
      pointsOfContact,
      customerSigners,
      organizationTransactionWitnesses,
      transactionType,
      orderProgress,
    } = transaction;

    let finalPointsOfContact = pointsOfContact.map((contact) => {
      // omit __typename
      if (contact.organizationAddress) {
        const { __typename, ...organizationAddress } = contact.organizationAddress;
        return {
          ...contact,
          organizationAddress,
        };
      }
      return contact;
    });
    if (hasCollaboratorSection(nonOnboardedTitleEnabled, transactionType)) {
      finalPointsOfContact = addCollabSupportToPointsOfContact(finalPointsOfContact);
    }
    const transactionName = transaction.name === DEFAULT_TRANSACTION_NAME ? null : transaction.name;
    const userTimezone = viewer?.user?.timezone;
    const currentNote = getNoteFromInstructions(instructions);

    // Also make sure that existing transactions from v1 can be loaded in v2
    const signingScheduleType = transaction.signingScheduleType;

    const {
      activationDate,
      activationTimezone,
      expirationDate,
      expirationTimezone,
      notaryMeetingTime,
      notaryMeetingTimezone,
    } = deserializeSigningSchedule({
      activationTimezone: transaction.activationTimezone,
      activationTime: transaction.activationTime,
      expiryTimezone: transaction.expiryTimezone,
      expiry: transaction.expiry,
      notaryMeetingTimezone: transaction.notaryMeetingTimezone,
      notaryMeetingTime: transaction.notaryMeetingTime,
      signingScheduleType,
      userTimezone,
    });

    this.initTransactionData = {
      transactionName,
      transactionType,
      notaryNote: currentNote ? currentNote.text : "",
      secondaryId: transaction.secondaryIdRequired,
      customerNote: transaction.message,
      subjectLine: transaction.messageSubject,
      emailSignature: transaction.messageSignature,
      recallReason: transaction.recallReason,
      isRecalled: transaction.recalled,
      recordingLocation: transaction.recordingLocation?.id,
      titleUnderwriter: transaction.titleUnderwriter?.id || null,
      titleAgency: transaction.titleAgency?.id,
      fileNumber: transaction.fileNumber,
      loanNumber: transaction.loanNumber,
      entityName: transaction.entityName,
      isTransactionForEntity: Boolean(transaction.entityName),
      smsAuthenticationRequired: transaction.authenticationRequirement === AuthTypes.SMS,
      activationTimezone,
      expirationTimezone,
      notaryMeetingTimezone,
      closerAssigneeId: transaction.closer?.id,
      notarizeCloserOverride: transaction.notarizeCloserOverride,
      activationDate,
      expirationDate,
      notaryMeetingTime,
      signingScheduleType,
      pointsOfContact: finalPointsOfContact,
      hasPointsOfContact: transaction.pointsOfContact && transaction.pointsOfContact.length > 0,
      customerSigners: customerSigners.map((customerSigner) => ({
        ...customerSigner,
        address: customerSigner.address.country ? customerSigner.address : null,
        // if is recipient group, override the email value to be the same as the sharedInboxEmail of the recipientGroup
        // so that form validations for signerDetails pass
        email: customerSigner.recipientGroup
          ? customerSigner.recipientGroup.sharedInboxEmail
          : customerSigner.email,
      })),
      organizationTransactionWitnesses,
      paperNoteConsent: transaction.paperNoteConsent,
      // Capitalized because thats the name that the propertyaddress form uses
      EditTransactionPropertyAddress: transaction.propertyAddress
        ? {
            line1: transaction.propertyAddress.line1,
            line2: transaction.propertyAddress.line2,
            city: transaction.propertyAddress.city,
            state: transaction.propertyAddress.state,
            postal: transaction.propertyAddress.postal,
            country: transaction.propertyAddress.country,
            lookup: formattedPropertyAddress(transaction.propertyAddress),
          }
        : null,
    };

    const readyToSendOrder =
      orderProgress?.label === OrganizationTransactionLabels.AWAITING_LENDER_APPROVAL;

    const showPlaceOrderUI =
      placeOrderLenderEnabledFlag &&
      !hasPermissionFor("manageOpenOrders") &&
      !readyToSendOrder &&
      !isHybridTransactionType(transactionType); // For now, we are only allowing full eClose orders to be placed

    this.state = {
      isSaving: false,
      showPlaceOrderUI,
    };
  }

  canSendToTitleAgency = () => {
    const {
      transaction: { transactionType, titleAgency, state },
      nonOnboardedTitleEnabled,
      realEstateCollabEnabled,
    } = this.props;
    return (
      realEstateCollabEnabled &&
      !isHybridTransactionType(transactionType) &&
      (titleAgency?.id || hasCollaboratorSection(nonOnboardedTitleEnabled, transactionType)) &&
      state === OrgTransactionStates.STARTED
    );
  };

  handleSave = (data) => {
    this.setState({
      isSaving: true,
    });
    return this.writeUpdateMutation(data)
      .catch((error) => {
        this.handleMutationError(error, false);
        throw new Error("Error updating transaction"); // throwing error so we don't try to save txn
      })
      .finally(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  handleSaveAndClose = (data) => {
    const {
      transaction: { id },
    } = this.props;

    this.handleSave(data)
      .then(() => {
        this.segmentAndExit(EVENT.ORGANIZATION_TRANSACTION_EDITOR_SAVE_EXIT, id);
      })
      .catch((_err) => {}); // ignore error here because it should be handled by handleSave
  };

  segmentAndExit = (segmentTrackEvent, id) => {
    segmentTrack(segmentTrackEvent, {
      organization_transaction_id: id,
      form: "lender",
      form_version: 2,
    });
    this.props.navigate(newPathWithPreservedSearchParams(TRANSACTION_PATH));
  };

  handleSend = (data, { forceSendToSigner = false, closingOpsOverride = false } = {}) => {
    const {
      transaction: { id },
      organization,
      sendOrganizationTransactionMutateFn,
      sendOrganizationTransactionToTitleAgencyMutateFn,
      sendOrganizationTransactionToClosingOpsMutateFn,
      intl,
      hasPermissionFor,
    } = this.props;
    const { showPlaceOrderUI } = this.state;

    let mutation;
    let input;
    let event;
    let genericErrorMessage = "There was an issue sending the transaction";

    if (this.canSendToTitleAgency() && !forceSendToSigner) {
      mutation = sendOrganizationTransactionToTitleAgencyMutateFn;
      input = { organizationTransactionId: id, closingOpsOverride };
    } else if (showPlaceOrderUI) {
      mutation = sendOrganizationTransactionToClosingOpsMutateFn;
      input = { organizationTransactionId: id };
      event = EVENT.ORGANIZATION_TRANSACTION_EDITOR_SEND_CLOSING_OPS;
      genericErrorMessage = "There was an issue placing an order";
    } else {
      mutation = sendOrganizationTransactionMutateFn;
      event = EVENT.ORGANIZATION_TRANSACTION_EDITOR_SEND;
      input = { id, closingOpsOverride };
    }

    if (organization.paymentSpecified) {
      this.setState({
        isSaving: true,
      });
      return this.writeUpdateMutation(data)
        .then(() => {
          return mutation({ variables: { input } })
            .then(() => {
              this.segmentAndExit(event, id);
            })
            .catch((errors) => {
              const errorString =
                getSendTransactionErrorString(errors, intl) || genericErrorMessage;
              const errorCode = errors?.graphQLErrors?.[0]?.code;
              if (errorCode === 412 && hasPermissionFor("manageOpenOrders")) {
                return {
                  closingOpsOverrideMessage: errorString,
                };
              }

              store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
            });
        })
        .catch(this.handleMutationError)
        .finally(() => {
          this.setState({
            isSaving: false,
          });
        });
    }
  };

  handleDeleteCustomerSigner = (customerSignerId) => {
    const { deleteOrganizationTranasctionCustomerMutateFn } = this.props;

    return deleteOrganizationTranasctionCustomerMutateFn({
      variables: { input: { id: customerSignerId } },
    }).catch((error) => {
      this.handleMutationError(error, false);
      throw new Error("Failed to delete customer signer");
    });
  };

  onUpdatePaperNoteConsent = (paperNoteExists) => {
    const { updatePaperNoteConsentMutateFn, transaction } = this.props;
    return updatePaperNoteConsentMutateFn({
      variables: {
        input: {
          transactionId: transaction.id,
          paperNoteConsent: paperNoteExists,
        },
      },
    })
      .then(() => {
        segmentTrack(EVENT.UPDATE_PAPER_NOTE_CONSENT, {
          paper_note_consent: paperNoteExists,
        });
      })
      .catch(this.handleMutationError);
  };

  onAddEnoteToTransaction = (s3FileHandle) => {
    const {
      addEnoteToTransactionMutateFn,
      transaction: { id },
      organization,
      intl,
    } = this.props;
    return addEnoteToTransactionMutateFn({
      variables: {
        input: {
          organizationTransactionId: id,
          fileHandle: s3FileHandle,
          organizationId: organization.id,
        },
      },
    }).catch((error) => {
      if (isGraphQLError(error) && error.graphQLErrors[0].message === "authentication_failed") {
        const errorString = intl.formatMessage(messages.vaultAuthenticationFailed);
        store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
      } else {
        return this.handleMutationError(error, false);
      }
    });
  };

  onInitializeEnoteSeed = (smartDocType) => {
    const {
      addEnoteToTransactionMutateFn,
      transaction: { id },
      organization,
    } = this.props;
    return addEnoteToTransactionMutateFn({
      variables: {
        input: {
          organizationTransactionId: id,
          smartDocType,
          organizationId: organization.id,
        },
      },
    }).catch(this.handleMutationError);
  };

  onRemoveDocumentFromTransaction = (documentId) => {
    const {
      removeDocumentFromTransactionMutateFn,
      transaction: { id },
    } = this.props;
    return removeDocumentFromTransactionMutateFn({
      variables: {
        input: {
          organizationTransactionId: id,
          documentId,
        },
      },
    }).catch(this.handleMutationError);
  };

  onRemoveEnoteSeed = () => {
    const {
      removeEnoteSeedMutateFn,
      transaction: {
        document_bundle: { id },
      },
    } = this.props;
    return removeEnoteSeedMutateFn({
      variables: {
        input: {
          documentBundleId: id,
        },
      },
    }).catch(this.handleMutationError);
  };

  onUpdateMortgageBorrower = (id, ssn) => {
    const { updateMortgageBorrowerMutateFn } = this.props;
    return updateMortgageBorrowerMutateFn({
      variables: {
        input: {
          id,
          ssn,
        },
      },
    }).catch(this.handleMutationError);
  };

  onSaveEnoteSeed = (enoteSeedFields, finalize = false) => {
    const {
      saveEnoteSeedMutateFn,
      transaction: {
        document_bundle: { id },
      },
      intl,
    } = this.props;
    return saveEnoteSeedMutateFn({
      variables: {
        input: {
          documentBundleId: id,
          enoteSeedFields,
          finalize,
        },
      },
    }).catch((err) => {
      if (isGraphQLError(err)) {
        const errorString = getEnoteSaveErrorString(err, intl);
        store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
      } else {
        captureException(err);
      }
    });
  };

  handleMutationError = (error, useGenericError = true) => {
    if (isGraphQLError(error)) {
      const { intl } = this.props;
      // Currently error.graphQLErrors[0].message is returning "Unprocessible Entity" using a better message
      const errorString = useGenericError
        ? intl.formatMessage(messages.updateError)
        : error.graphQLErrors[0].message;
      store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
    } else {
      captureException(error);
    }
  };

  writeUpdateMutation({
    transactionName,
    transactionType,
    entityName,
    secondaryId,
    subjectLine,
    customerNote,
    emailSignature,
    fileNumber,
    loanNumber,
    recordingLocation,
    titleUnderwriter,
    activationDate,
    expirationDate,
    notaryMeetingTime,
    signingScheduleType,
    EditTransactionPropertyAddress,
    notaryNote,
    pointsOfContact,
    customerSigners,
    organizationTransactionWitnesses,
    recallReason,
    smsAuthenticationRequired,
    titleAgency = null,
    notarizeCloserOverride,
    closerAssigneeId,
    ...otherProps
  }) {
    const {
      updateTransactionMutateFn,
      transaction,
      transaction: { id, paperNoteConsent },
      organization,
      nonOnboardedTitleEnabled,
    } = this.props;

    const normalizedPointsOfContact = normalizePointsOfContact(
      nonOnboardedTitleEnabled,
      transaction.transactionType,
      pointsOfContact,
    );
    // We take the id's from the transaction customerSigners and map them onto the form customerSigners
    const formattedCustomerSigners = customerSigners.map((signer, index) => {
      return {
        id: transaction?.customerSigners?.[index]?.id,
        ...signer,
        // if this is a signing group clear the email value so it is not updated on the server
        ...(signer.recipientGroup ? { email: null } : {}),
      };
    });

    const formattedOrganizationTransactionWitnesses = organizationTransactionWitnesses.map(
      (witness, index) => {
        return {
          ...witness,
          id: witness.id || transaction?.organizationTransactionWitnesses[index]?.id,
        };
      },
    );

    const signingSchedule = serializeSigningSchedule({
      expirationDate,
      signingScheduleType,
      activationDate,
      notaryMeetingTime,
      expirationTimezone: otherProps.expirationTimezone,
    });

    const activationExpiration = {
      activationTime: signingSchedule.activationTime,
      expiry: signingSchedule.expiry,
      activationTimezone: signingSchedule.activationTimezone,
      expiryTimezone: signingSchedule.expiryTimezone,
    };

    const authRequirement =
      organization.defaultAuthenticationRequirement === AuthTypes.SMS
        ? smsAuthenticationRequired
          ? AuthTypes.SMS
          : AuthTypes.NONE
        : transaction.authRequirement;

    const updateMutationPromise = updateTransactionMutateFn({
      variables: {
        input: {
          id,
          organizationId: organization.id,
          transactionMemberships: {
            titleAgencyId: titleAgency,
          },
          transaction: {
            titleAgencyOrgId: titleAgency,
            name: transactionName,
            transactionType,
            secondaryIdRequired: secondaryId,
            entityName,
            message: customerNote,
            messageSubject: subjectLine,
            messageSignature: emailSignature,
            recallReason,
            requiredAuth: authRequirement,
            // recordingLocation is not available in "Other" type transactions
            recordingLocationId: recordingLocation,
            titleUnderwriterOrgId: titleUnderwriter ? titleUnderwriter : null,
            paperNoteConsent: isHybridTransactionType(transactionType) ? true : paperNoteConsent,

            // Activation and Expiration
            ...activationExpiration,

            fileNumber,
            loanNumber,
            streetAddress: EditTransactionPropertyAddress
              ? {
                  line1: EditTransactionPropertyAddress.line1,
                  line2: EditTransactionPropertyAddress.line2,
                  city: EditTransactionPropertyAddress.city,
                  state: EditTransactionPropertyAddress.state,
                  postal: EditTransactionPropertyAddress.postal,
                  country: EditTransactionPropertyAddress.country,
                }
              : null,
          },
          contacts: (normalizedPointsOfContact || []).map((contact) => ({
            id: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            phoneNumber: contact.phoneNumber,
            email: contact.email,
            role: contact.role,
            title: contact.title,
            shownToSigner: contact.shownToSigner,
            accessToTransaction:
              contact.accessToTransaction && isHybridTransactionType(transactionType),
            organizationName: contact.organizationName,
            organizationAddress: contact.organizationAddress,
          })),
          customers: encodeCustomerSigners(formattedCustomerSigners),
          witnesses: formattedOrganizationTransactionWitnesses.map((witness) => ({
            id: witness.id,
            email: witness.email,
            phoneNumber: witness.phoneNumber,
            firstName: witness.firstName,
            middleName: witness.middleName,
            lastName: witness.lastName,
          })),
          signingSchedule,
          notarizeCloserOverride: Boolean(notarizeCloserOverride),
          closerAssigneeId:
            notarizeCloserOverride || !closerAssigneeId || closerAssigneeId === UNASSIGNED_CLOSER
              ? null
              : closerAssigneeId,
        },
      },
    });

    return updateMutationPromise.then((result) => {
      const { customerSigners } =
        result.data.updateOrganizationTransactionV2.organization_transaction;
      if (!isEqual(formattedCustomerSigners, customerSigners)) {
        otherProps.change("customerSigners", customerSigners);
      }
      return result;
    });
  }

  handleUpdateNotaryInstruction = (instruction) => {
    this.setState({
      isSaving: true,
    });
    const {
      upsertBundleInstructionMutateFn,
      transaction,
      transaction: { id: transactionId },
      organization,
      intl,
    } = this.props;

    return upsertBundleInstructionMutateFn({
      variables: {
        input: {
          documentBundleId: transaction.document_bundle.id,
          text: instruction.text,
          organizationId: transaction.organization.id,
          documentBundleInstructionId: instruction.id,
        },
      },
      update(cache, { data: { upsertDocumentBundleInstruction } }) {
        if (instruction.id) {
          return;
        }
        const { transaction, ...rest } = cache.readQuery({
          query: TransactionEditQuery,
          variables: {
            transactionId,
            organizationId: organization.id,
            userTagList: LENDER_EDIT_QUERY_USER_TAG_LIST,
          },
        });
        const newTransaction = {
          ...transaction,
          document_bundle: {
            ...transaction.document_bundle,
            instructions: [
              ...transaction.document_bundle.instructions,
              upsertDocumentBundleInstruction.documentBundleInstruction,
            ],
          },
        };
        cache.writeQuery({
          query: TransactionEditQuery,
          variables: {
            transactionId,
            organizationId: organization.id,
            userTagList: LENDER_EDIT_QUERY_USER_TAG_LIST,
          },
          data: { transaction: newTransaction, ...rest },
        });
      },
    })
      .catch((error) => {
        const isGraphError = isGraphQLError(error);
        if (isGraphError) {
          const errorString =
            error.graphQLErrors?.[0]?.message || intl.formatMessage(messages.genericError);
          store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
        } else {
          captureException(error);
        }
      })
      .finally(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  handleDeleteNotaryInstruction = (id) => {
    this.setState({
      isSaving: true,
    });
    const { deleteDocumentBundleInstructionMutateFn, intl } = this.props;

    return deleteDocumentBundleInstructionMutateFn({
      variables: { input: { id } },
    })
      .catch((error) => {
        const isGraphError = isGraphQLError(error);
        if (isGraphError) {
          const errorString =
            error.graphQLErrors?.[0]?.message || intl.formatMessage(messages.genericError);
          store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
        } else {
          captureException(error);
        }
      })
      .finally(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  getSendLabel = () => {
    const canSendToTitleAgency = this.canSendToTitleAgency();
    const { showPlaceOrderUI } = this.state;

    if (canSendToTitleAgency) {
      return (
        <FormattedMessage
          id="4b7be4e6-8760-474f-b1a7-d88e7fb75d71"
          defaultMessage="Send to Title Agency"
        />
      );
    }
    if (showPlaceOrderUI) {
      return (
        <FormattedMessage id="6b3074e9-d240-49c2-9613-cc08555d0f7c" defaultMessage="Place Order" />
      );
    }

    return null;
  };

  render() {
    if (!this.state || !this.initTransactionData) {
      return null;
    }

    const { isSaving } = this.state;

    const {
      transaction,
      viewer,
      organization,
      mutationLoading,
      enoteMutationLoading,
      nonOnboardedTitleEnabled,
      enableEnotesInHybrids,
      hasPermissionFor,
    } = this.props;
    const { showPlaceOrderUI } = this.state;

    const usersOrgCreatedTransaction =
      organization.id === transaction.organization.id ||
      Boolean(
        organization.subsidiaryOrganizations.find((o) => o.id === transaction.organization.id),
      );

    const req = getRequirements(transaction.transactionType, "lender");
    const showEnoteSection =
      (enableEnotesInHybrids &&
        Object.values(LENDER_HYBRID_TRANSACTION_TYPES).includes(transaction.transactionType)) ||
      isConditional(req[MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]);

    return (
      <div className={CX_NAME}>
        {isSaving && <LoadingIndicator />}
        <TransactionEditForm
          hasCollaboratorSection={hasCollaboratorSection(
            nonOnboardedTitleEnabled,
            transaction.transactionType,
          )}
          isFullRON={[
            LENDER_TRANSACTION_TYPES.REFINANCE,
            LENDER_TRANSACTION_TYPES.PURCHASE_BUYER_LOAN,
          ].includes(transaction.transactionType)}
          initialData={this.initTransactionData}
          transaction={transaction}
          viewer={viewer}
          organization={organization}
          onSaveAndClose={this.handleSaveAndClose}
          onSend={this.handleSend}
          disabledSubmit={mutationLoading || this.state.isSaving}
          onSave={this.handleSave}
          onDeleteCustomerSigner={this.handleDeleteCustomerSigner}
          onUpdatePaperNoteConsent={this.onUpdatePaperNoteConsent}
          onAddEnoteToTransaction={this.onAddEnoteToTransaction}
          onRemoveDocumentFromTransaction={this.onRemoveDocumentFromTransaction}
          onUpdateMortgageBorrower={this.onUpdateMortgageBorrower}
          onRemoveEnoteSeed={this.onRemoveEnoteSeed}
          onSaveEnoteSeed={this.onSaveEnoteSeed}
          onInitializeEnoteSeed={this.onInitializeEnoteSeed}
          enoteMutationLoading={enoteMutationLoading}
          showEnoteSection={showEnoteSection}
          sendLabel={this.getSendLabel()}
          showSendToSignerButton={
            this.canSendToTitleAgency() && hasPermissionFor("manageOpenOrders")
          }
          onDeleteNotaryInstruction={this.handleDeleteNotaryInstruction}
          onUpdateNotaryInstruction={this.handleUpdateNotaryInstruction}
          usersOrgCreatedTransaction={usersOrgCreatedTransaction}
          showPlaceOrderUI={showPlaceOrderUI}
          canSendToSigner={
            (!showPlaceOrderUI && !this.canSendToTitleAgency()) ||
            hasPermissionFor("manageOpenOrders")
          }
        />
      </div>
    );
  }
}

EditTransactionContainer.propTypes = {
  transaction: PropTypes.shape({
    name: PropTypes.string,
    state: PropTypes.string.isRequired,
    document_bundle: PropTypes.shape({
      id: PropTypes.string,
      includesEnote: PropTypes.bool,
      documents: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
              isEnote: PropTypes.bool,
              name: PropTypes.string,
              s3OriginalAsset: PropTypes.shape({
                url: PropTypes.string,
              }),
            }),
          }),
        ),
      }),
      instructions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
    orderProgress: PropTypes.shape({
      label: PropTypes.string,
    }),
    transactionType: PropTypes.oneOf(Object.values(ALL_LENDER_TRANSACTION_TYPES)),
    secondaryIdRequired: PropTypes.bool,
    message: PropTypes.string,
    messageSubject: PropTypes.string,
    messageSignature: PropTypes.string,
    recordingLocation: PropTypes.shape({
      id: PropTypes.string.isRequired,
      erecordingSupported: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    }),
    titleAgency: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    titleUnderwriter: PropTypes.shape({
      id: PropTypes.string,
    }),
    fileNumber: PropTypes.string,
    loanNumber: PropTypes.string,
    signingScheduleType: PropTypes.oneOf(Object.values(SIGNING_SCHEDULE_TYPES)),
    activationTimezone: PropTypes.string,
    activationTime: PropTypes.string,
    expiryTimezone: PropTypes.string,
    expiry: PropTypes.string,
    notaryMeetingTimezone: PropTypes.string,
    notaryMeetingTime: PropTypes.string,
    pointsOfContact: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    customerSigners: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    propertyAddress: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      city: PropTypes.string,
      postal: PropTypes.string,
      country: PropTypes.string,
    }),
    paperNoteConsent: PropTypes.bool,
  }),
  viewer: PropTypes.shape({
    user: PropTypes.shape({
      organizationMembership: PropTypes.shape({
        role: PropTypes.string,
      }),
    }),
  }),
  sendOrganizationTransactionMutateFn: PropTypes.func.isRequired,
  sendOrganizationTransactionToTitleAgencyMutateFn: PropTypes.func.isRequired,
  updateTransactionMutateFn: PropTypes.func.isRequired,
  upsertBundleInstructionMutateFn: PropTypes.func.isRequired,
  deleteDocumentBundleInstructionMutateFn: PropTypes.func.isRequired,
  deleteOrganizationTranasctionCustomerMutateFn: PropTypes.func.isRequired,
  updatePaperNoteConsentMutateFn: PropTypes.func.isRequired,
  addEnoteToTransactionMutateFn: PropTypes.func.isRequired,
  removeDocumentFromTransactionMutateFn: PropTypes.func.isRequired,
  updateMortgageBorrowerMutateFn: PropTypes.func.isRequired,
  removeEnoteSeedMutateFn: PropTypes.func.isRequired,
  saveEnoteSeedMutateFn: PropTypes.func.isRequired,
  sendOrganizationTransactionToClosingOpsMutateFn: PropTypes.func.isRequired,
  enoteMutationLoading: PropTypes.bool,
  mutationLoading: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  hasPermissionFor: PropTypes.func.isRequired,
};

function useEnoteMutations() {
  const [updateMortgageBorrowerMutateFn, { loading: updateMortgageBorrowerLoading }] =
    useRawMutation(UpdateMortgageBorrowerMutation);
  const [updatePaperNoteConsentMutateFn, { loading: paperNoteConsentLoading }] = useRawMutation(
    UpdatePaperNoteConsentMutation,
    {},
    SUPPRESS_OPTIONS_ANALYTICS,
  );
  const [addEnoteToTransactionMutateFn, { loading: addEnoteToTransactionLoading }] = useRawMutation(
    AddEnoteToTransactionMutation,
    {},
    SUPPRESS_OPTIONS_ANALYTICS,
  );
  const [removeDocumentFromTransactionMutateFn, { loading: removeDocumentFromTransactionLoading }] =
    useRawMutation(RemoveDocumentFromTransactionMutation);
  const [removeEnoteSeedMutateFn, { loading: removeEnoteSeedLoading }] =
    useRawMutation(RemoveEnoteSeedMutation);
  const [saveEnoteSeedMutateFn, { loading: saveEnoteSeedLoading }] =
    useRawMutation(SaveEnoteSeedMutation);
  return {
    updatePaperNoteConsentMutateFn,
    addEnoteToTransactionMutateFn,
    removeDocumentFromTransactionMutateFn,
    updateMortgageBorrowerMutateFn,
    removeEnoteSeedMutateFn,
    saveEnoteSeedMutateFn,
    enoteMutationLoading:
      paperNoteConsentLoading ||
      addEnoteToTransactionLoading ||
      removeDocumentFromTransactionLoading ||
      updateMortgageBorrowerLoading ||
      removeEnoteSeedLoading ||
      saveEnoteSeedLoading,
  };
}

function EditTransactionContainerInner({ transactionId, data }) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const navigate = useNavigate();
  const {
    updatePaperNoteConsentMutateFn,
    addEnoteToTransactionMutateFn,
    removeDocumentFromTransactionMutateFn,
    updateMortgageBorrowerMutateFn,
    removeEnoteSeedMutateFn,
    enoteMutationLoading,
    saveEnoteSeedMutateFn,
  } = useEnoteMutations();
  const sendOrganizationTransactionMutateFn = useMutation(SendOrganizationTransactionMutation);
  const sendOrganizationTransactionToTitleAgencyMutateFn = useMutation(
    SendOrganizationTransactionToTitleAgencyMutation,
  );
  const [
    sendOrganizationTransactionToClosingOpsMutateFn,
    { loading: sendOrganizationTransactionToClosingOpsLoading },
  ] = useRawMutation(SendOrganizationTransactionToClosingOpsMutation);
  const [updateTransactionMutateFn, { loading: updateTransactionMutationLoading }] = useRawMutation(
    UpdateOrganizationTransactionMutation,
  );
  const [upsertBundleInstructionMutateFn, { loading: upsertBundleInsructionLoading }] =
    useRawMutation(UpsertDocumentBundleInstructionMutation);
  const [deleteDocumentBundleInstructionMutateFn, { loading: deleteBundleInstructionLoading }] =
    useRawMutation(DeleteDocumentBundleInstructionMutation);
  const [
    deleteOrganizationTranasctionCustomerMutateFn,
    { loading: deleteOrganizationTranasctionCustomerLoading },
  ] = useRawMutation(DeleteOrganizationTranasctionCustomerMutation);
  const { editable } = data.transaction;
  useEffect(() => {
    if (!editable) {
      navigate(transactionDetailsRoute(transactionId), { replace: true });
    }
  }, [editable, navigate]);

  return (
    <EditTransactionContainer
      intl={intl}
      hasPermissionFor={hasPermissionFor}
      transactionId={transactionId}
      nonOnboardedTitleEnabled={Boolean(data.organization.nonOnboardedTitleEnabled)}
      enableEnotesInHybrids={useFeatureFlag(ENABLE_ENOTES_IN_HYBRIDS)}
      realEstateCollabEnabled={Boolean(data.organization.realEstateCollabEnabled)}
      placeOrderLenderEnabledFlag={Boolean(data.organization.placeAnOrderLenderEnabled)}
      transaction={data.transaction}
      viewer={data.viewer}
      organization={data.organization}
      navigate={navigate}
      mutationLoading={Boolean(
        updateTransactionMutationLoading ||
          upsertBundleInsructionLoading ||
          deleteBundleInstructionLoading ||
          deleteOrganizationTranasctionCustomerLoading ||
          sendOrganizationTransactionToClosingOpsLoading ||
          enoteMutationLoading,
      )}
      sendOrganizationTransactionMutateFn={sendOrganizationTransactionMutateFn}
      sendOrganizationTransactionToTitleAgencyMutateFn={
        sendOrganizationTransactionToTitleAgencyMutateFn
      }
      updateTransactionMutateFn={updateTransactionMutateFn}
      upsertBundleInstructionMutateFn={upsertBundleInstructionMutateFn}
      deleteDocumentBundleInstructionMutateFn={deleteDocumentBundleInstructionMutateFn}
      deleteOrganizationTranasctionCustomerMutateFn={deleteOrganizationTranasctionCustomerMutateFn}
      updatePaperNoteConsentMutateFn={updatePaperNoteConsentMutateFn}
      enoteMutationLoading={enoteMutationLoading}
      addEnoteToTransactionMutateFn={addEnoteToTransactionMutateFn}
      removeDocumentFromTransactionMutateFn={removeDocumentFromTransactionMutateFn}
      updateMortgageBorrowerMutateFn={updateMortgageBorrowerMutateFn}
      sendOrganizationTransactionToClosingOpsMutateFn={
        sendOrganizationTransactionToClosingOpsMutateFn
      }
      removeEnoteSeedMutateFn={removeEnoteSeedMutateFn}
      saveEnoteSeedMutateFn={saveEnoteSeedMutateFn}
    />
  );
}

function WithV3Banner(props) {
  return useTransactionCreationV3(props.user) ? (
    <Navigate replace to={transactionEditRouteV3(props.transactionId)} />
  ) : (
    props.children
  );
}

function EditTransactionContainerWithData() {
  const [activeOrganizationId] = useActiveOrganization();
  const { transactionId } = useParams();

  return (
    <QueryWithLoading
      query={TransactionEditQuery}
      variables={{
        transactionId,
        organizationId: activeOrganizationId,
        userTagList: LENDER_EDIT_QUERY_USER_TAG_LIST,
      }}
    >
      {({ data, loading, error }) => {
        // QueryWithLoading only loads for the first load and so we need this to catch subsequent loads with a different transaction id
        if (loading) {
          return <LoadingIndicator />;
        }
        if (!data?.transaction) {
          return <TransactionErrorRedirect error={error} />;
        }

        const transaction = data.transaction;

        if (!transaction.editable) {
          return <Navigate replace to={transactionDetailsRoute(transaction.id)} />;
        }

        return (
          <WithV3Banner transactionId={transactionId} user={data.viewer.user}>
            <EditTransactionContainerInner data={data} transactionId={transactionId} />
          </WithV3Banner>
        );
      }}
    </QueryWithLoading>
  );
}

export default EditTransactionContainerWithData;
