import "./index.scss";

import { useMemo, useCallback, type ComponentProps } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useMatch, useNavigate, useSearchParams, Outlet } from "react-router-dom";

import KbaAlertBanner from "common/banners/kba_alert_banner";
import {
  mortgageDashboardDeserializer,
  mortgageDashboardSerializer,
} from "common/mortgage/transactions/dashboard/filter";
import Dashboard, {
  DashboardContent,
  DashboardHeader,
  DashboardBody,
  DashboardTitle,
  DashboardFilters,
  DashboardActionBar,
  DashboardBanner,
} from "common/dashboard";
import { BulkActionBar } from "common/core/bulk_actions";
import {
  BulkActionClearButton,
  BulkActionSeparator,
  useBulkActions,
} from "common/core/bulk_actions/common";
import {
  BulkTransactionDeleteModal,
  useBulkDelete,
  BulkActionDeleteButton,
} from "common/dashboard/bulk_actions/transaction_delete";
import {
  BulkActionArchiveButton,
  BulkActionUnarchiveButton,
  useBulkArchive,
} from "common/dashboard/bulk_actions/transaction_archive";
import {
  StatusFilter,
  TransactionTypeFilter,
  OrderProgressFilter,
  useDateConstraint,
  TransactionSectionNamespace,
  ClearAllButton,
  getActiveFiltersCount,
  ClosingDateFilterV2,
  TransactionSubsectionNamespace,
  useToggleSetWithCallback,
  type ClosingDateFilter,
} from "common/dashboard/filter_dropdown/common";
import { BulkTable } from "common/core/table";
import TablePagination, { usePagination } from "common/core/table/pagination";
import TableSearch from "common/core/table/search";
import LoadingIndicator from "common/core/loading_indicator";
import { useActiveOrganization } from "common/account/active_organization";
import { useNewSetupPageAnalytics } from "common/mortgage/transactions/setup";
import {
  OrganizationTransactionStatus,
  OrganizationTransactionDetailedStatus,
  MortgageTransactionType,
  UserAction,
  OrganizationTransactionLabels,
  OrganizationTransactionVariant,
  Feature,
  AlertName,
  TransactionScope,
  type SortDirection,
  type OrganizationTransactionColumn,
} from "graphql_globals";
import { useQuery, useMutation } from "util/graphql";
import {
  transactionEditRoute,
  transactionDetailsRouteV2,
  SETTINGS_PATH,
  TRANSACTION_PATH,
} from "util/routes";
import {
  TransactionTypeColumn,
  LoanNumberColumn,
  PrimaryBorrowerColumn,
  USStateColumn,
  AddressColumn,
  TitleAgencyColumn,
  ClosingDateColumn,
  StatusColumn,
  OrganizationNameColumn,
  OrderProgressColumn,
  OrderProgressLastUpdatedColumn,
  IdentityIssueColumn,
  RiskLevelColumn,
} from "common/dashboard/columns";
import { useId } from "util/html";
import { useDebouncedQuery, useFilter } from "common/dashboard/filter";
import { IdentityIssueAlerts } from "common/dashboard/identity_issues";
import { RealEstateNewTransactionDropdown } from "common/dashboard/real_estate_new_transaction_dropdown";
import { usePermissions } from "common/core/current_user_role";
import { useProofDefendUpsell } from "util/feature_detection";

import DeleteOrganizationTransactionsMutation from "./delete_organization_transactions.mutation.graphql";
import LenderDashboardGraph, {
  type LenderDashboard_viewer as Viewer,
  type LenderDashboard_node_Organization as ActiveOrganization,
} from "./index.query.graphql";
import LenderTransactionsGraph, {
  type LenderTransactions_node_Organization_transactions_edges_node as OrganizationTransaction,
} from "./transactions.query.graphql";
import TransactionsSidebar from "./transactions_sidebar";
import EmptyResults from "./empty_results";
import { getSubTab } from "./subtab";

const PAGE_SIZE = 50;

const MESSAGES = defineMessages({
  searchPlacholder: {
    id: "74022eb2-0acd-4dfa-8c6c-35dc48191df3",
    defaultMessage: "Search by signer, loan #, or transaction ID",
  },
  searchLabel: {
    id: "3f9d1a4d-2a4e-4d2a-9d6f-3e3f1a3a6a4d",
    defaultMessage: "Search for transactions",
  },
  caption: {
    id: "6cae4177-3463-4917-aa64-ceb89d4601b9",
    defaultMessage: "Transactions",
  },
});

const draftStatuses = [
  OrganizationTransactionDetailedStatus.DRAFT,
  OrganizationTransactionDetailedStatus.RECALLED,
];

const readyToSendProgresses = [OrganizationTransactionLabels.AWAITING_LENDER_APPROVAL];

const getFilterVariables = ({
  pageIndex,
  activeOrganizationId,
  selectedTransactionTypes,
  startDate,
  endDate,
  query,
  selectedStatuses,
  section,
  subSection,
  orderProgress,
  sortBy,
}: {
  pageIndex: number;
  activeOrganizationId: string;
  selectedTransactionTypes: Set<MortgageTransactionType>;
  startDate: string | null;
  endDate: string | null;
  query: string | null;
  selectedStatuses: Set<OrganizationTransactionDetailedStatus>;
  section: TransactionSectionNamespace;
  subSection: TransactionSubsectionNamespace | null;
  orderProgress: Set<OrganizationTransactionLabels>;
  sortBy?: { column: OrganizationTransactionColumn; direction: SortDirection };
}) => {
  const commonVariables = {
    offset: PAGE_SIZE * pageIndex,
    organizationId: activeOrganizationId,
    transactionTypes: Array.from(selectedTransactionTypes.values()),
    closingDateStart: startDate,
    closingDateEnd: endDate,
    archived: section === TransactionSectionNamespace.ARCHIVED,
    riskyTransactionsOnly: subSection === TransactionSubsectionNamespace.IDENTITY_RISK,
    duplicatedTransactionsOnly: section === TransactionSectionNamespace.DUPLICATED,
    alerts: subSection === TransactionSubsectionNamespace.KBA_ISSUES ? [AlertName.KBA_FAILURE] : [],
    sortBy: sortBy ? [sortBy] : [],
  };
  if (section === TransactionSectionNamespace.OPEN_ORDER) {
    return {
      ...commonVariables,
      query,
      detailedStatuses: [OrganizationTransactionDetailedStatus.OPEN_ORDER],
      labels: Array.from(orderProgress.values()),
    };
  }
  if (subSection === TransactionSubsectionNamespace.CREATED_BY_ME) {
    return {
      ...commonVariables,
      query,
      scope: TransactionScope.created_by_me,
      detailedStatuses: [],
    };
  }
  return {
    ...commonVariables,
    query,
    detailedStatuses: Array.from(selectedStatuses.values()),
  };
};

type Props = {
  viewer: Viewer;
  activeOrganization: ActiveOrganization;
};

function getRelatedProgressStatuses(opts: {
  showPlaceOrderUI: boolean;
}): OrganizationTransactionDetailedStatus[] {
  return [
    OrganizationTransactionDetailedStatus.SENT_TO_SIGNER,
    OrganizationTransactionDetailedStatus.ACTIVE,
    OrganizationTransactionDetailedStatus.VIEWED,
    OrganizationTransactionDetailedStatus.PARTIALLY_COMPLETE,
    OrganizationTransactionDetailedStatus.ATTEMPTED,
    opts.showPlaceOrderUI && OrganizationTransactionDetailedStatus.ORDER_PLACED,
    OrganizationTransactionDetailedStatus.SENT_TO_TITLE_AGENT,
    OrganizationTransactionDetailedStatus.CONVERTED_TO_WET_SIGN,
    OrganizationTransactionDetailedStatus.AWAITING_PAYMENT,
    OrganizationTransactionDetailedStatus.ON_HOLD,
  ].filter(Boolean);
}

function LenderTransactionsV2({ viewer, activeOrganization }: Props) {
  const navigate = useNavigate();
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const transactionsActive = Boolean(
    useMatch({
      path: TRANSACTION_PATH,
      end: false,
    }),
  );

  const showPlaceOrderUI = Boolean(
    activeOrganization.placeAnOrderEnabled && !hasPermissionFor("manageOpenOrders"),
  );
  const proofDefendUpsell = useProofDefendUpsell(activeOrganization);

  const inProgressStatuses = useMemo(
    () =>
      getRelatedProgressStatuses({
        showPlaceOrderUI:
          (activeOrganization.placeAnOrderEnabled ||
            activeOrganization.perTransactionPlaceAnOrderEnabled) &&
          !hasPermissionFor("manageOpenOrders"),
      }),
    [],
  );

  const completeStatuses = useMemo(
    () => [
      OrganizationTransactionDetailedStatus.COMPLETE,
      OrganizationTransactionDetailedStatus.ESIGN_COMPLETE,
      ...(activeOrganization.wetSignUploadEnabled
        ? [OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE]
        : []),
      OrganizationTransactionDetailedStatus.COMPLETE_WITH_REJECTIONS,
    ],
    [],
  );

  const otherOptions = useMemo(
    () =>
      [
        ...(hasPermissionFor("manageOpenOrders")
          ? [
              [
                OrganizationTransactionStatus.ACTION_NEEDED,
                OrganizationTransactionDetailedStatus.OPEN_ORDER,
              ],
            ]
          : []),
        [
          OrganizationTransactionStatus.LIVE,
          OrganizationTransactionDetailedStatus.MEETING_IN_PROGRESS,
        ],
        [OrganizationTransactionStatus.FAILURE, OrganizationTransactionDetailedStatus.EXPIRED],
        [OrganizationTransactionStatus.INACTIVE, OrganizationTransactionDetailedStatus.DRAFT],
        [OrganizationTransactionStatus.INACTIVE, OrganizationTransactionDetailedStatus.RECALLED],
      ] as [OrganizationTransactionStatus, OrganizationTransactionDetailedStatus][],
    [],
  );

  const [rawQueryArgs] = useSearchParams();
  const { handleChange, deserializedArgs } = useFilter(
    mortgageDashboardDeserializer,
    mortgageDashboardSerializer,
  );
  const {
    page: pageIndex,
    detailedStatuses: selectedStatuses,
    transactionTypes: selectedTransactionTypes,
    dateConstraint: selectedDateConstraint,
    orderProgress: selectedOrderProgressTypes,
    section,
    query,
    subSection,
    sortColumn,
    sortDirection,
  } = deserializedArgs;

  const { textFilterValue, handleTextFilterChange } = useDebouncedQuery(handleChange, query, true);

  const organizationHasDuplication = activeOrganization.featureList.includes(
    Feature.DUPLICATE_TRANSACTIONS,
  );
  const showDuplicateUI = hasPermissionFor("duplicateTransaction") && organizationHasDuplication;

  const setPageIndex = useCallback(
    (page: number) => {
      handleChange({ page });
    },
    [handleChange],
  );

  const {
    clearSelection: clearStatusesSelection,
    removeAll: removeAllStatuses,
    addAll: addAllStatuses,
    toggleSelection: toggleStatusSelection,
  } = useToggleSetWithCallback(selectedStatuses, handleChange, "detailedStatuses");

  const {
    clearSelection: clearTransactionTypesSelection,
    toggleSelection: toggleTransactionTypeSelection,
    removeAll: removeAllTransactionTypes,
    addAll: addAllTransactionTypes,
  } = useToggleSetWithCallback(selectedTransactionTypes, handleChange, "transactionTypes");

  const setSelectedDateConstraint = useCallback<
    ComponentProps<typeof ClosingDateFilter>["setSelectedConstraint"]
  >(
    (dateConstraint) => {
      handleChange({ dateConstraint: dateConstraint || undefined, page: 0 });
    },
    [handleChange],
  );

  const { startDate, endDate } = useDateConstraint(
    selectedDateConstraint,
    setSelectedDateConstraint,
  );

  const {
    clearSelection: clearOrderProgressSelection,
    removeAll: removeAllOrderProgress,
    addAll: addAllOrderProgress,
    toggleSelection: toggleOrderProgressSelection,
  } = useToggleSetWithCallback(selectedOrderProgressTypes, handleChange, "orderProgress");

  const setSortValue = useCallback(
    (sortColumn: OrganizationTransactionColumn, sortDirection?: SortDirection) => {
      if (!sortDirection) {
        handleChange({ sortColumn: null, sortDirection, section: undefined });
      } else {
        handleChange({ sortColumn, sortDirection });
      }
    },
    [handleChange],
  );

  const sortBy =
    sortColumn && sortDirection ? { column: sortColumn, direction: sortDirection } : undefined;

  const variables = getFilterVariables({
    pageIndex,
    activeOrganizationId: activeOrganization.id,
    selectedTransactionTypes,
    startDate,
    endDate,
    query,
    selectedStatuses,
    section,
    orderProgress: selectedOrderProgressTypes,
    subSection,
    sortBy,
  });
  const activeFiltersCount = getActiveFiltersCount({
    ...variables,
    // dont consider OPEN_ORDER status as a filter when in open order section
    detailedStatuses:
      section === TransactionSectionNamespace.OPEN_ORDER ? [] : variables.detailedStatuses,
  });

  const deleteOrganizationTransactionsMutateFn = useMutation(
    DeleteOrganizationTransactionsMutation,
  );

  const {
    data,
    loading: resultsLoading,
    previousData,
    refetch,
  } = useQuery(LenderTransactionsGraph, {
    variables,
  });

  const organization = data
    ? data.node!
    : resultsLoading && previousData
      ? previousData.node! // previousData is used to make sure dashboard isn't just wiped clean while querying for new data
      : null;

  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  const handleIdentityIssueNotificationClicked = useCallback(
    (transactionId: string) => {
      refetch(); // may not need refetch once txn detail modal removed.
      navigate(transactionDetailsRouteV2({ id: transactionId, queryParams: rawQueryArgs }));
    },
    [refetch, rawQueryArgs],
  );

  const transactions = organization ? organization.transactions : null;
  const totalCount = transactions?.totalCount ?? 0;
  const pageCount = Math.max(Math.ceil(totalCount / PAGE_SIZE), 1);

  const columns = useMemo(() => {
    if (section === TransactionSectionNamespace.OPEN_ORDER) {
      return [
        IdentityIssueColumn,
        TransactionTypeColumn(intl),
        OrderProgressColumn,
        OrderProgressLastUpdatedColumn,
        LoanNumberColumn,
        AddressColumn,
        PrimaryBorrowerColumn,
        ClosingDateColumn(sortBy, setSortValue),
        USStateColumn,
        TitleAgencyColumn,
      ];
    }
    return [
      IdentityIssueColumn,
      TransactionTypeColumn(intl),
      ...(activeOrganization.proofDefend ? [RiskLevelColumn] : []),
      StatusColumn,
      LoanNumberColumn,
      OrganizationNameColumn,
      PrimaryBorrowerColumn,
      ClosingDateColumn(sortBy, setSortValue),
      AddressColumn,
      USStateColumn,
      TitleAgencyColumn,
    ];
  }, [activeOrganization.id, viewer, deserializedArgs]);

  const items = useMemo(
    () => (transactions ? transactions.edges.map(({ node }) => node) : []),
    [transactions],
  );

  const {
    toggleItem,
    toggleAllItems,
    clearAllItems,
    selectedItemCount,
    selectedItemIdsSet,
    selectedItemIdsArray,
    selectAllCheckboxState,
  } = useBulkActions(items);

  const { preventDelete, showBulkDeleteModal, setShowBulkDeleteModal } = useBulkDelete(
    items,
    selectedItemIdsArray,
  );

  const { preventArchive } = useBulkArchive(items, selectedItemIdsArray);

  const { track: trackNewSetupPageEvent } = useNewSetupPageAnalytics();

  const { canNextPage, canPreviousPage, nextPage, previousPage, startIndex, endIndex } =
    usePagination({
      disabled: resultsLoading,
      pageIndex,
      pageCount,
      pageSize: PAGE_SIZE,
      items,
      onPageChange: setPageIndex,
    });

  const getTransactionLinkProps = useCallback(
    (transaction: OrganizationTransaction) => {
      const { id, userAction, transactionVariant } = transaction;

      const editRoute = transactionEditRoute({
        id,
        queryParams: rawQueryArgs,
        type:
          transactionVariant === OrganizationTransactionVariant.PROOF
            ? "proof"
            : transactionVariant === OrganizationTransactionVariant.ESIGN
              ? "esign"
              : undefined,
      });
      const detailsRoute = transactionDetailsRouteV2({ id, queryParams: rawQueryArgs });

      // Lenders will eventually be able to edit wet sign transactions that have been created by the API.
      // However, for now we want to prevent them from being able to edit drafts of wet sign transactions.
      if (transaction.type === MortgageTransactionType.wet_sign) {
        return {
          to: detailsRoute,
          role: "button",
        };
      }

      const route =
        userAction === UserAction.EDIT
          ? editRoute
          : userAction === UserAction.PAY
            ? SETTINGS_PATH
            : detailsRoute;
      return {
        to: route,
        role:
          transaction.status === OrganizationTransactionStatus.INACTIVE ||
          transaction.organization.id === organization?.id
            ? undefined
            : "button",
      };
    },
    [rawQueryArgs],
  );

  const handleRowClick = useCallback(
    (transaction: OrganizationTransaction) => {
      const { to } = getTransactionLinkProps(transaction);
      navigate(to);
    },
    [navigate, getTransactionLinkProps],
  );

  const handleNewTransactionClick = () => {
    trackNewSetupPageEvent("new-transaction-link", {
      orgType: "lender",
    });
    navigate("/transaction/setup/v2");
  };

  const allTransactionsCount = organization?.allTransactionsCount.totalCount ?? 0;
  const noResults = totalCount === 0;
  const noFilteredResults = allTransactionsCount > 0 && noResults;
  const emptyResults = !resultsLoading && noResults;
  const tableSearchResultsId = useId();

  const bulkUnarchiveButton = (
    <BulkActionUnarchiveButton ids={selectedItemIdsArray} selectedItemCount={selectedItemCount} />
  );

  const bulkArchiveButton = (
    <BulkActionArchiveButton
      ids={selectedItemIdsArray}
      selectedItemCount={selectedItemCount}
      disabled={preventArchive}
    />
  );

  const bulkDeleteButton = (
    <BulkActionDeleteButton
      onClick={() => setShowBulkDeleteModal(true)}
      disabled={preventDelete}
      canRecall
    />
  );

  const onDelete = () => {
    return deleteOrganizationTransactionsMutateFn({
      variables: {
        ...variables,
        input: {
          ids: selectedItemIdsArray,
        },
      },
    });
  };

  const subTab = getSubTab(
    deserializedArgs,
    draftStatuses,
    readyToSendProgresses,
    inProgressStatuses,
    selectedStatuses,
    selectedOrderProgressTypes,
    organization?.readyToSendTransactionsCount.totalCount ?? 0,
    hasPermissionFor("manageOpenOrders"),
  );

  const transactionDropdown = hasPermissionFor("createOrganizationTransactions") ? (
    <RealEstateNewTransactionDropdown
      portal="lender"
      handleRealEstateOption={handleNewTransactionClick}
      showPlaceOrderUI={showPlaceOrderUI}
      orgIsActive={Boolean(organization ? organization.active : true)}
    />
  ) : null;

  const archivedDashboardTitle = (
    <DashboardTitle
      title={
        <FormattedMessage id="3e797498-957b-4bbe-912b-6855bc41a689" defaultMessage="Archived" />
      }
      description={
        <FormattedMessage
          id="a4d00737-daad-43be-8f60-1b81dd419da8"
          defaultMessage="This table shows transactions that have been archived by you and your team."
        />
      }
      buttons={transactionDropdown}
    />
  );

  const transactionDashboardTitle = (
    <>
      {section === TransactionSectionNamespace.OPEN_ORDER ? (
        <FormattedMessage id="5fb4d546-1c9c-4c5b-97c2-108bea24d5c7" defaultMessage="Open orders" />
      ) : (
        <FormattedMessage
          id="ba3bfd39-6e17-4443-89a2-f850fa7c1b9e"
          defaultMessage="All Transactions"
        />
      )}
    </>
  );

  const riskDashboardTitle = (
    <DashboardTitle
      title={
        <FormattedMessage id="d8518ef3-c45a-48bf-9aae-cc78dc7d3ed6" defaultMessage="Risk Alerts" />
      }
    />
  );

  const dashboardTitle =
    section === TransactionSectionNamespace.ARCHIVED ? (
      archivedDashboardTitle
    ) : subSection === TransactionSubsectionNamespace.IDENTITY_RISK ? (
      riskDashboardTitle
    ) : (
      <DashboardTitle title={transactionDashboardTitle} buttons={transactionDropdown} />
    );

  return (
    <div className="LenderDashboard">
      <IdentityIssueAlerts
        organizationId={activeOrganization.id}
        onAlert={handleIdentityIssueNotificationClicked}
      />
      <Dashboard>
        <TransactionsSidebar
          inProgressStatuses={inProgressStatuses}
          organization={organization}
          showDuplicateUI={showDuplicateUI}
          readyToSendProgresses={readyToSendProgresses}
          draftStatuses={draftStatuses}
          subTab={subTab}
          section={section}
          transactionsActive={transactionsActive}
        />
        {transactionsActive ? (
          <div className="LenderDashboardContentContainer">
            <DashboardBanner>
              <KbaAlertBanner organizationId={activeOrganization.id} />
            </DashboardBanner>
            <DashboardContent>
              {dashboardTitle}
              <DashboardHeader>
                <DashboardFilters>
                  {section === TransactionSectionNamespace.OPEN_ORDER ? (
                    <>
                      <TableSearch
                        value={textFilterValue}
                        placeholder={intl.formatMessage(MESSAGES.searchPlacholder)}
                        aria-label={intl.formatMessage(MESSAGES.searchLabel)}
                        onChange={handleTextFilterChange}
                        aria-describedby={noFilteredResults ? tableSearchResultsId : undefined}
                      />
                      <div className="SmallerDropdown">
                        <TransactionTypeFilter
                          selectedTypes={selectedTransactionTypes}
                          clearSelection={clearTransactionTypesSelection}
                          toggleSelection={toggleTransactionTypeSelection}
                          addAll={addAllTransactionTypes}
                          removeAll={removeAllTransactionTypes}
                          disabled={resultsLoading}
                        />
                      </div>
                      <OrderProgressFilter
                        disabled={resultsLoading}
                        clearSelection={clearOrderProgressSelection}
                        toggleSelection={toggleOrderProgressSelection}
                        selectedOrderProgressTypes={selectedOrderProgressTypes}
                        addAll={addAllOrderProgress}
                        removeAll={removeAllOrderProgress}
                        availableTransactionLabels={organization?.availableTransactionLabels}
                      />
                      {
                        <ClosingDateFilterV2
                          disabled={resultsLoading}
                          selectedConstraint={selectedDateConstraint}
                          setSelectedConstraint={setSelectedDateConstraint}
                        />
                      }
                    </>
                  ) : (
                    <>
                      <TableSearch
                        value={textFilterValue}
                        placeholder={intl.formatMessage(MESSAGES.searchPlacholder)}
                        aria-label={intl.formatMessage(MESSAGES.searchLabel)}
                        onChange={handleTextFilterChange}
                        aria-describedby={noFilteredResults ? tableSearchResultsId : undefined}
                      />
                      <div className="SmallerDropdown">
                        {section !== TransactionSectionNamespace.ARCHIVED && (
                          <StatusFilter
                            selectedStatuses={selectedStatuses}
                            inProgressStatuses={inProgressStatuses}
                            completeStatuses={completeStatuses}
                            otherOptions={otherOptions}
                            clearSelection={clearStatusesSelection}
                            removeAll={removeAllStatuses}
                            addAll={addAllStatuses}
                            toggleSelection={toggleStatusSelection}
                            disabled={resultsLoading}
                          />
                        )}
                      </div>
                      <div className="SmallerDropdown">
                        <TransactionTypeFilter
                          selectedTypes={selectedTransactionTypes}
                          clearSelection={clearTransactionTypesSelection}
                          toggleSelection={toggleTransactionTypeSelection}
                          addAll={addAllTransactionTypes}
                          removeAll={removeAllTransactionTypes}
                          disabled={resultsLoading}
                        />
                      </div>
                      {
                        <ClosingDateFilterV2
                          disabled={resultsLoading}
                          selectedConstraint={selectedDateConstraint}
                          setSelectedConstraint={setSelectedDateConstraint}
                        />
                      }
                    </>
                  )}
                  {activeFiltersCount > 0 && (
                    <ClearAllButton
                      activeFiltersCount={activeFiltersCount}
                      onClick={() => {
                        handleTextFilterChange({ value: "" });
                        handleChange(
                          {
                            section,
                            subSection,
                          },
                          true,
                        );
                      }}
                    />
                  )}
                </DashboardFilters>
              </DashboardHeader>

              {!emptyResults && (
                <DashboardActionBar
                  pagination={
                    <TablePagination
                      canPreviousPage={canPreviousPage}
                      canNextPage={canNextPage}
                      nextPage={nextPage}
                      previousPage={previousPage}
                      startIndex={startIndex}
                      endIndex={endIndex}
                      totalCount={totalCount}
                    />
                  }
                >
                  {selectedItemCount > 0 && (
                    <BulkActionBar itemCount={selectedItemCount}>
                      <BulkActionClearButton onClick={clearAllItems} />
                      <BulkActionSeparator />
                      {section === TransactionSectionNamespace.ARCHIVED
                        ? bulkUnarchiveButton
                        : bulkArchiveButton}
                      {section !== TransactionSectionNamespace.ARCHIVED && bulkDeleteButton}
                    </BulkActionBar>
                  )}
                </DashboardActionBar>
              )}

              <DashboardBody>
                {emptyResults ? (
                  <EmptyResults
                    tableSearchResultsId={tableSearchResultsId}
                    handleRealEstateOption={handleNewTransactionClick}
                    noFilteredResults={noFilteredResults}
                    subTab={subTab}
                    activeFiltersCount={activeFiltersCount}
                    proofDefendUpsell={proofDefendUpsell}
                  />
                ) : (
                  <BulkTable
                    toggleItem={toggleItem}
                    toggleAllItems={toggleAllItems}
                    selectedItemIds={selectedItemIdsSet}
                    selectAllCheckboxState={selectAllCheckboxState}
                    data={items}
                    columns={columns}
                    loading={resultsLoading}
                    caption={intl.formatMessage(MESSAGES.caption)}
                    rowInteraction={{
                      onClick: handleRowClick,
                      getLinkProps: getTransactionLinkProps,
                    }}
                    totalItems={totalCount}
                    getAutomationId={(transaction: OrganizationTransaction) =>
                      `transaction-row-${transaction.id}`
                    }
                  />
                )}
              </DashboardBody>
            </DashboardContent>
          </div>
        ) : (
          <Outlet />
        )}
      </Dashboard>

      {showBulkDeleteModal && (
        <BulkTransactionDeleteModal
          onDelete={onDelete}
          onClose={() => setShowBulkDeleteModal(false)}
          totalTransactions={selectedItemIdsArray.length}
        />
      )}

      {transactionsActive && <Outlet />}
    </div>
  );
}

function LenderTransactionsV2Wrapper() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(LenderDashboardGraph, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const viewer = data!.viewer;
  const organization = data!.node!;
  if (organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }
  return <LenderTransactionsV2 viewer={viewer} activeOrganization={organization} />;
}

export default LenderTransactionsV2Wrapper;
